'use strict';

Gri.module({
  name: 'header-alpha',
  ieVersion: null,
  $el: $('.header-alpha'),
  container: '.header-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    let windowH = $(window).height();
    let $searchLink = $this.find('.link-search');
    let $menuLink = $this.find('.link-menu');
    let $searchContainer = $('.search-alpha-wrapper');
    let $menuWrapper = $('.header-alpha-menu-wrapper');
    let $menuBreadcrumb = $('.header-alpha-menu-breadcrumb');
    let $menuDesktop = $('.header-alpha-desktop');
    let thisHeight = $this.height();
    let $cardAlpha = $this.find('.card-alpha');
    let $menuCloseButton = $(".header-alpha-desktop .right-area .link-close");
    let heights = [];
    // endregion

    // region run
    $('body').css('margin-top', thisHeight);

    resizeController();
    $(window).on('resize', resizeController);

    setTimeout(function () {
      $cardAlpha.each(function () {
        let thisHeight = $(this).outerHeight();
        heights.push(thisHeight);
      });

      let maxHeight = Math.max.apply(undefined, heights);
      $cardAlpha.outerHeight(maxHeight);
    }, 1000);
    // endregion

    // region events
    // search open
    $searchLink.on('click', function () {
      $searchContainer.addClass('open');
      $('.menu-alpha').removeClass('opened');
      $menuDesktop.find('.menu a').removeClass('active');
      $menuDesktop.find('.submenu .sub-first a').removeClass('active');
      $menuDesktop.find('.submenu .sub-second').removeClass('active');
      setTimeout(function () {
        $menuDesktop.find('.submenu').removeClass('active long');
      }, 500);
      esc()
    });

    function esc() {
      document.addEventListener("keydown", function (event) {
        if (event.keyCode === 27) {
          $searchContainer.removeClass('open');
          $inputWrapper.find('input').val('');
          $resultContainer.find('li').remove();
        }
      });
    }

    // mobile menu wrapper open
    $menuLink.on('click', function () {
      if($(this).hasClass('clicked')) {
        $('body').removeClass('locked');
        $(this).removeClass('clicked');
        $menuWrapper.removeClass('open');
        setTimeout(function () {
          $menuWrapper.find('ul').removeClass('active'); // menü kapandığında itemları gizle
          $menuBreadcrumb.find('.back').removeClass('active');
          $menuBreadcrumb.find('.text-wrapper p').text($menuBreadcrumb.find('.text-wrapper p').data('text'));
          $('.header-alpha-menu').find('.support').removeClass('active');
        }, 500);
      } else {
        $('body').addClass('locked');
        $(this).addClass('clicked');
        $menuWrapper.addClass('open');
      }
    });

    // mobile menu open
    $menuWrapper.find('.main > li > a').on('click', function () {
      if ($(this).parents('li').find('> ul').length > 0) {
        $(this).parents('li').find('> ul').addClass('active');
        $menuBreadcrumb.find('.back').addClass('active');
        $menuBreadcrumb.find('.text-wrapper p').attr('data-prev', $menuBreadcrumb.find('.text-wrapper p').text());
        let thisText = $(this).text();
        $menuBreadcrumb.find('.text-wrapper p').text(thisText);
      }
    });

    // mobile sub child menu open
    $menuWrapper.find('.main > li > .sub > li > a').on('click', function () {
      if ($(this).parents('li').find('> ul').length > 0) {
        $(this).parents('li').find('> ul').addClass('active');
        $menuBreadcrumb.find('.back').addClass('active');
        $menuBreadcrumb.find('.text-wrapper p').attr('data-prev', $menuBreadcrumb.find('.text-wrapper p').text());
        let thisText = $(this).text();
        $menuBreadcrumb.find('.text-wrapper p').text(thisText);
      }
    });

    // mobile support menu open
    $menuWrapper.find('.main .support-trigger').on('click', function () {
      if ($('.header-alpha-menu').find('.support').hasClass('active')) {
        $('.header-alpha-menu').find('.support').removeClass('active');
      } else {
        $('.header-alpha-menu').find('.support').addClass('active');
        $menuBreadcrumb.find('.back').addClass('active');
        $menuBreadcrumb.find('.text-wrapper p').attr('data-prev', $menuBreadcrumb.find('.text-wrapper p').text());
        let thisText = $(this).text();
        $menuBreadcrumb.find('.text-wrapper p').text(thisText);
      }
    });

    $menuDesktop.find('.support-trigger').on('click', function () {
      $menuDesktop.find('.submenu').removeClass('active long');
      $menuDesktop.find('.submenu .sub-first a').removeClass('active');
      $menuDesktop.find('.submenu .sub-second').removeClass('active');
      if ($('.menu-alpha').hasClass('opened')) {
        $('.menu-alpha').removeClass('opened');
        $(this).removeClass('active');
      } else {
        $('.menu-alpha').addClass('opened');
        $menuDesktop.find('.menu > ul > li > a').removeClass('active');
        $(this).addClass('active');
      }
    });

    // mobile breadcrumb control
    $menuBreadcrumb.find('.back').on('click', function () {
      $menuBreadcrumb.find('.text-wrapper p').text($menuBreadcrumb.find('.text-wrapper p').data('text'));
      let activeLength = Number($menuWrapper.find('.main ul.active').length);
      $('.header-alpha-menu').find('.support').removeClass('active');
      if(activeLength == 2) {
        $menuWrapper.find('.main ul li ul').removeClass('active');
        $menuBreadcrumb.find('.text-wrapper p').text($menuBreadcrumb.find('.text-wrapper p').data('prev'));
      } else if (activeLength == 1) {
        $menuWrapper.find('.main ul').removeClass('active');
        $menuBreadcrumb.find('.text-wrapper p').text($menuBreadcrumb.find('.text-wrapper p').data('text'));
        $(this).removeClass('active');
      } else {
        $(this).removeClass('active');
      }
    });

    // desktop menu open
    $menuDesktop.find('.menu > ul > li > a').not('.support-trigger').on('click', function () {
      $menuDesktop.find('.submenu').removeClass('long');
      if($(this).hasClass('active')) {
        $(this).removeClass('active');
        $('.submenu').removeClass('active');
        $('.sub-second').removeClass('active');
        $menuDesktop.find('.submenu .sub-first li a').removeClass('active');
        $menuDesktop.find('.submenu .sub-second').removeClass('active');
        $menuCloseButton.removeClass("active");
      } else {
        $menuDesktop.find('.menu > ul > li > a').removeClass('active');
        $('.menu-alpha').removeClass('opened');
        $(this).addClass('active');
        let thisText = $(this).text();
        $('.submenu').removeClass('active');
        $('.submenu[data-sub="' + thisText + '"]').addClass('active');
        $menuCloseButton.addClass("active");
      }
    });

    // desktop menu close
    $menuCloseButton.on('click', function() {
      $menuDesktop.find('.menu > ul > li > a').removeClass("active");
      $('.submenu').removeClass('active');
      $('.sub-second').removeClass('active');
      $menuDesktop.find('.submenu .sub-first li a').removeClass('active');
      $menuDesktop.find('.submenu .sub-second').removeClass('active');
      $menuCloseButton.removeClass("active");
    });

    // desktop sub child menu open
    $menuDesktop.find('.submenu .sub-first li a').on('click', function () {
      $menuDesktop.find('.submenu .sub-first li').removeClass('active');
      let thisText = $(this).text();
      let subChildElement = $('.sub-second[data-subchild="' + thisText + '"]');
      if (subChildElement.length == 0) {
        $(this).parents('.submenu').removeClass('long');
      } else {
        $(this).parents('.submenu').addClass('long');
      }
      $(this).parent().addClass('active');
      $('.sub-second').removeClass('active');
      subChildElement.addClass('active');
    });
    // endregion

    // region events functions

    // endregion

    // region functions
    function resizeController() {
      let windowW = $(window).width();
      if(windowW >= 992) {

      } else {
        $menuWrapper.css({
          top: thisHeight,
          height: windowH - thisHeight
        });

        $('.header-alpha-menu').find('.support').css({
          top: thisHeight,
          height: windowH - thisHeight
        });
      }
    }
    // endregion

    var dontClose = function() {
      $(document).mousedown(function (e) {
        var container = $("[dont-close]");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          $('.header-alpha-desktop').find('.menu > ul > li > a').removeClass("active");
          $('.submenu').removeClass('active');
          $('.sub-second').removeClass('active');
          $('.header-alpha-desktop').find('.submenu .sub-first li a').removeClass('active');
          $('.header-alpha-desktop').find('.submenu .sub-second').removeClass('active');
        }
      });
    }

    dontClose();

  }
});
